import React, { useEffect } from 'react';

import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Button from '@mui/material/Button';



let server_url = 'https://mapi2.newsy.today';




function AddSource (){



    useEffect(()=>{
 
        console.log('testing AddSrouce');
        
    
      });


      async function create_data(dataObject){
  
  
        return new Promise(function (resolve,reject){

          try{

                    /*
                    const token = localStorage.getItem('AccessToken'); 
                    let options = {
                      headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                      },
                      insecure: true,
                      rejectUnauthorized: false
                    }

                    let myheaders = {
                      'Authorization': 'Bearer ' + token,
                      'Content-Type': 'application/json'
                    };
                    */

                    let myheaders = {
                      'Content-Type': 'application/json'
                    };

                      
            
                      fetch(server_url + "/create_data",{
                        method: 'POST',
                        //headers: { 'Content-Type': 'application/json' },
                        headers: myheaders,
                        body: JSON.stringify(dataObject)
                        })
                          .then(response => response.json())
                          .then(function(data){
                                  console.log('xxx');
                                  console.log(data);
                                  console.log('xxx2');
                                  //resolve(data);
                          
                          });
          }
          catch(err){
                  console.log(err);
                  reject(err);
          }

          })




      }
    


    return(


      <Box sx={{ '& > :not(style)': { m: 1 } }}>
      <FormControl variant="standard">


      <div>
        <TextField
          label="Code"
          id="code"
          defaultValue=""
          size="small"
          margin="dense"
        />
      </div>
          
  

      <div>
        <TextField
          label="FeedName"
          id="feedname"
          defaultValue=""
          size="small"
          margin="dense"
        />
       
      </div>
       
      <div>
        <TextField
          label="URL"
          id="url"
          defaultValue=""
          size="small"
          margin="dense"
        />
       
      </div>

      <div>
        <TextField
          label="Status"
          id="status"
          defaultValue=""
          size="small"
          margin="dense"
        />
       
      </div>


      <div>
      <Button variant="text" onClick={()=>{

            let code = document.getElementById('code').value;
            let feed = document.getElementById('feedname').value;
            let url = document.getElementById('url').value;
            let status = document.getElementById('status').value;
            console.log(code + " " + feed + " " + url);

            let jsonData = {
              code: code,
              feed: feed,
              url: url,
              status: status
            }

            create_data(jsonData);


      }}>Submit</Button>

       
      </div>



          
          
        </FormControl>    
 

    </Box>



    );
   }
    
   export default AddSource;