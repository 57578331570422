import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import {useNavigate} from 'react-router-dom'





export default function NewsMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    console.log(e.target.id);

    let location = e.target.id;
    if (location === 'addsource'){
        navigate('/addsource', { replace: true })
        //history.push("/addsource");
    }
    if (location === 'viewsources'){
      navigate('/viewsources', { replace: true })
      //history.push("/addsource");
  }

  if (location === 'mysources'){
    navigate('/mysources', { replace: true })
    //history.push("/addsource");
}

if (location === 'topics'){
  navigate('/topics', { replace: true })
  //history.push("/addsource");
}

    if (location === 'news'){
        navigate('/', { replace: true })
        //history.push("/addsource");
    }
    setAnchorEl(null);
  };

  const navigate = useNavigate();


  return (
    <span>
      <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            //sx={{ mr: 2 }}
            onClick={handleClick}
          >
            <MenuIcon />
          </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem id='news' onClick={handleClose}>News</MenuItem>
        <MenuItem id='mysources' onClick={handleClose}>My Sources</MenuItem>
        <br />
        -- Super Admin  --
        <MenuItem id='addsource' onClick={handleClose}>Add Source</MenuItem>
        <MenuItem id='viewsources' onClick={handleClose}>View Sources</MenuItem>
        <MenuItem onClick={handleClose}>Stream</MenuItem>
      </Menu>
    </span>
  );
}