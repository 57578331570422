import React, { useEffect } from 'react';

import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Button from '@mui/material/Button';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@material-ui/core';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';


import moment from 'moment';




let server_url = 'https://mapi2.newsy.today';
let web_url = 'https://newsy.today';



function MyCard(props){


    let RowData = props.RowData;
    let item = props.RowData;

    console.log('FFF:' + JSON.stringify(RowData));



            console.log(RowData.Title);

            let age = moment().unix() - parseInt(RowData.firstseen);

            const storyObj = {

              fontSize: 14,
              fontWeight: "bold",
              //color: "#4a54f1",
              color: "#3D4849",
              textAlign: "left",
              paddingLeft: "10px",
              paddingTop: "0px",
              textDecoration: 'none',
              //backgroundColor: "#f0f2f5"
          
              
          }

          const styleObj = {

            fontSize: 11,
            color: "grey",
            textAlign: "left",
            paddingLeft: "10px",
            paddingTop: "0px",
            textDecoration: 'none',
            //backgroundColor: "#f0f2f5"
        }

        let tagtype = "neutral";

        let timeSeconds = Math.round((moment().unix() - parseInt(item.firstseen))/60);




            return(



              <Card variant="outlined"  sx={{ minWidth: 400 }} >
              <dl style={storyObj} tagtype={tagtype}>
                <dt style={storyObj}><a href={item.url} style={{ textDecoration: 'none',  color: "#3D4849"}} target="_blank">{item.title}</a></dt>
                <dt style={styleObj}><b>Sentiment:</b> {item.sentiment} | <b>Age</b>:{timeSeconds} minutes ago | <b>Feed</b>: {item.feed_title}  Diff: {item.diff} | Topic: {item.topic} | Hits: {item.value}</dt> 
              </dl> 
            </Card>


            
            );



}

function TopStories (){


    const [TopStories, setTopStories] = React.useState([]);


    async function reload(){
      let mystories = await get_topstories();
      setTopStories(mystories);
      console.log(mystories);
    }


    useEffect(()=>{
 
        //console.log('testing AddSrouce');
        reload();
        
    
      },[]);


      async function get_topstories(dataObject){
  
  
        return new Promise(function (resolve,reject){

          try{

                 

            let myheaders = {
                'Content-Type': 'application/json'
              };

                
      
                fetch('/static/json/topstories.json',{
                  method: 'GET',
                  //headers: { 'Content-Type': 'application/json' },
                  headers: myheaders,
                  //body: JSON.stringify(dataObject)
                  })
                    .then(response => response.json())
                    .then(function(data){
                            console.log('xxx');
                            console.log(data);
                            console.log('xxx2');
                            //setSources(data);
                            data.sort((a, b) => a.value < b.value ? 1 : -1)
                            resolve(data)
                            //resolve(data);
                    
                    });

                      
            
                      
          }
          catch(err){
                  console.log(err);
                  reject(err);
          }

          })




      }
    


    return(


    <Box>
    

      <div>
      <Button variant="text" onClick={async ()=>{

            
        reload();


      }}>Reload</Button>


      <div>

        {
        TopStories.map((item, index)=>{

                //console.log(item.title);
                return(
                <MyCard key={index} RowData={item}></MyCard>
                );

        })
        }

        </div>

       
      </div>



          
      
 

    </Box>



    );
   }
    
   export default TopStories;