import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = {
  value: 0,
}

export const statsSlice = createSlice({
  name: 'stats',
  initialState,
  reducers: {
    setStats: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value = action.payload
    },
    clear: (state) => {
      state.value = 0
    }
    ,
    refresh: (state) => {
        state.value = 1
    }
  },
})




// Action creators are generated for each case reducer function
export const { setStats } = statsSlice.actions

export default statsSlice.reducer