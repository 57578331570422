import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';

import { useSelector, useDispatch } from 'react-redux';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';





import { DataGrid } from '@mui/x-data-grid';

import { TopicData } from '../redux/TopicSlice';







//let server_url = 'https://mapi2.newsy.today';
let server_url = process.env.REACT_APP_SERVER_URL;


function MyCard(props){


    let RowData = props.RowData;

    console.log('FFF:' + JSON.stringify(RowData));




            return(
            <Card sx={{ minWidth: 275 }} >


            {/*
            <CardActionArea id={RowData.sourceid} onClick={()=>{
                let item = {RowData};
                //console.log(JSON.stringify(item["RowData"]));
                let sourceid = item["RowData"].sourceid;

                //console.log('card clicked:' + e);
                console.log(sourceid);
              }}>
                  */}
  
          
            <CardContent>
      
             
                <Typography variant="h2" color="text.secondary">
               {RowData.AccountCircle}
            

                </Typography>

            </CardContent>
             <CardActions>
             <Checkbox />{RowData.name}
              
            </CardActions>
        </Card>
            
            );



}

function Topics (){



    const [Topics, setTopics] = useState([]);

    const [RowData, setRowData] = useState([]);
    const [RowKeys, setRowKeys] = useState([]);
    const [ColumnData, setColumnData] = useState([]);


    const dispatch = useDispatch()



    const myTopic = useSelector((state) => state.TopicData.value);


    useEffect(()=>{
 
        console.log('testing AddSrouce');

        
        let source_data = gdata();
        //console.log(source_data);
    
      }, []);



      async function gdata(){

        //let data = await get_data();
        let data = await fetch_cached_topics();
        setTopics(data);
        console.log(data);

    }




    //Set RowIDS

   
    useEffect(()=>{


      //setRowData(Topics);
      //setRowKeys(Object.keys(Topics));
  
      //Setup Headers
      let toplen = 0;
  
      let myObject = new Object;
      let rowArray = new Array;
      console.log("L: " + Topics.length);
      let x=0; const xMax = Topics.length -1; for(; x<=xMax; x++){


            console.log('xxxx: ' + x);
  
           // setTopics[x]['id'] = x; //Add id key to each field

           //console.log()
  
  
            //let mykeys = Object.keys(Topics]);
  
           
           
            //Add Headers
            /*
           mykeys.forEach((item) => {
           let headerdata = {
              field: item,
              headerName: item,
              width: 120,
              editable: true,
              
            };
            */
  
            //myObject[Topics[x].topic_name] = headerdata;
  
            //Add Rows
            //{ id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
            //console.log(jdata[x]);
            
  
  
  
  
   
  
          if(x == xMax){
            
            
         
  

            /*
             //add custom columns
             myObject["note"] = {
              field: "Note",
              headerName: "Note",
              width: 150,
              editable: true,
            };
  
            myObject["ledgeramount"] = {
              field: "LedgerAmount",
              headerName: "Ledger Amount",
              width: 180,
              editable: true,
            };

           
            */
  

            /*
            let items = Object.keys(myObject);
            let colArray = new Array();
            let z=0; const zMax = items.length - 1; for(; z <= zMax; z++){
                colArray.push(myObject[items[z]]);

              
  
                if(z===zMax){
  
                  console.log(colArray);
                  setColumnData(colArray);
                }
  
              }
            
             
  
  
  
            }
            */
  
  
          }
  
           
      
  
        }
  
  



    }, [Topics]);

    



    async function update_sources(sourcesJson){
  
  
      return new Promise(function (resolve,reject){

        try{

                  /*
                  const token = localStorage.getItem('AccessToken'); 
                  let options = {
                    headers: {
                      'Authorization': 'Bearer ' + token,
                      'Content-Type': 'application/json'
                    },
                    insecure: true,
                    rejectUnauthorized: false
                  }

                  let myheaders = {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                  };
                  */

                  let myheaders = {
                    'Content-Type': 'application/json'
                  };

                    
                 
                  
                    fetch(server_url + "/update_topics",{
                      method: 'POST',
                      //headers: { 'Content-Type': 'application/json' },
                      headers: myheaders,
                       body: JSON.stringify(sourcesJson)
                      })
                        .then(response => response.json())
                        .then(function(data){
                                console.log('xxx');
                                console.log(data);
                                console.log('xxx2');
                                setTopics(data);
                                resolve(data)
                                //resolve(data);
                        
                        });
        }
        catch(err){
                console.log(err);
                reject(err);
        }

        })

      }



      async function fetch_cached_topics(){


        //let server_url = 'http://127.0.0.1:3401';
        return new Promise(function (resolve,reject){
      
          try{
                          //fetch(server_url + "/stories?timestamp='" + timestamp + "'")
                          fetch("/static/json/topics.json")
                          .then(response => response.json())
                          .then(function(data){
                                  
                                  //console.log(data);
                                  resolve(data);
                          
                          });
          }
          catch(err){
                  console.log(err);
                  reject(err);
          }
      
          })
      
      
      
      }

      async function get_data(){
  
  
        return new Promise(function (resolve,reject){

          try{

                    /*
                    const token = localStorage.getItem('AccessToken'); 
                    let options = {
                      headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                      },
                      insecure: true,
                      rejectUnauthorized: false
                    }

                    let myheaders = {
                      'Authorization': 'Bearer ' + token,
                      'Content-Type': 'application/json'
                    };
                    */

                    let myheaders = {
                      'Content-Type': 'application/json'
                    };

                      
            
                      fetch(server_url + "/get_topics",{
                        method: 'GET',
                        //headers: { 'Content-Type': 'application/json' },
                        headers: myheaders,
                        //body: JSON.stringify(dataObject)
                        })
                          .then(response => response.json())
                          .then(function(data){
                                  console.log('xxx');
                                  console.log(data);
                                  console.log('xxx2');
                                  setTopics(data);
                                  resolve(data)
                                  //resolve(data);
                          
                          });
          }
          catch(err){
                  console.log(err);
                  reject(err);
          }

          })




      }

      const handleGetRowId = (e) => {
        return e.sourceid
    }


      const columns = [
        { field: 'sourceid', headerName: 'ID', width: 90 },
        {
          field: 'cnt',
          headerName: 'Country',
          width: 150,
          editable: true,
        },
        {
          field: 'code',
          headerName: 'Code',
          width: 150,
          editable: true,
        },
        {
            field: 'name',
            headerName: 'Name',
            width: 150,
            editable: true,
          },
          {
            field: 'status',
            headerName: 'Status',
            width: 150,
            editable: true,
          },
          {
            field: 'url',
            headerName: 'URL',
            width: 150,
            editable: true,
          },
          {
            field: 'web',
            headerName: 'WEb',
            width: 150,
            editable: true,
          }
        ]
    


        const handleCellEditCommit = (updateitem) => {

          console.log('hERE');
  
          console.log(updateitem);
      
          let id = updateitem.id;
      
          let newRowData = new Array();
      
          let i=0; const iMax = RowData.length -1; for(; i <= iMax; i++){
      
            let item = RowData[i];
            let cid = item.id;
            if(id == cid){
      
              console.log('Match');
              //console.log(row);
              console.log(item);
              let field = updateitem.field;
              let value = updateitem.value;
              item[field] = value;
              console.log(updateitem);
              
              //update Row witn new Row
              newRowData.push(item);
      
            } 
            else{
      
              newRowData.push(item);
            }
    
           
      
            if(i === iMax){
              console.log('Setting New RowData');
              setRowData(newRowData);
              console.log(newRowData);
            }

          }
      
      }
        

      const [editRowsModel, setEditRowsModel] = React.useState({});

      const handleEditRowsModelChange = React.useCallback((model) => {
        console.log('yes');
        setEditRowsModel(model);
        console.log(model);
      }, []);


    return(

   

    <Card variant="outlined" sx={{ border: 0, p: 3, bgcolor: '#2E3B55' }}  /*sx={{ minWidth: 400 }}*/>

        { Topics.map((item, index) => {

            let topic_url = "view_topic?topic=" + item.topic_name;

            return(
                
                //[{item.topic_name}]

                
               
                <a id={item.topic_name} onClick={(e)=>{

                    console.log('open search popup');
                    //dispatch(search({'term': SearchTerm}));

                    console.log(e.target.id);
                    dispatch(TopicData(e.target.id));
        
        
                    }} href="/" style={{ color: "#fff", textDecoration: "none" }}>[ {item.topic_name} ]</a> 

              
            
           
            
            )

        })}

</Card>





    );
   }
    
   export default Topics;