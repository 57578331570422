//import * as React from 'react';

import React, {useCallback, useState, useMemo, useEffect} from 'react'
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import FilterListIcon from '@mui/icons-material/FilterList';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import RefreshIcon from '@mui/icons-material/Refresh';

import AnnouncementIcon from '@mui/icons-material/Announcement';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';


import Collapse from '@mui/material/Collapse';
import Tooltip from '@mui/material/Tooltip';

import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';


import { useSelector, useDispatch } from 'react-redux';

import {useNavigate} from 'react-router-dom'


import { decrement, increment } from '../redux/counterSlice';
import { on, off} from '../redux/filterSlice';
import { refresh, search } from '../redux/storySlice';
import { setStats } from '../redux/statsSlice';

import { AccessData } from '../redux/authSlice';
import { updateMySources } from '../redux/mySourcesSlice';

import NewsMenu from './newsmenu';
import MySourcesMenu from './mysourcesmenu';
//import Topics from './topics';

import { Label } from '@mui/icons-material';

import SwipeableTopicDrawer from './swipeabletopicdrawer';



const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

export default function PrimarySearchAppBar() {


  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);

  const [LoggedIn, setLoggedIn] = React.useState(false); 
  const [UserName, setUserName] = React.useState(); 

  const [SearchActive, setSearchActive] = React.useState(1);

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const isNewsMenuOpen = Boolean(anchorEl2);

  //const [LoggedIn,setLoggedIn] = React.useState();


  const navigate = useNavigate();

  const handleOpenMenu = (event) => {
    setAnchorEl2(event.currentTarget);
  };


  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = (e) => {

  

    console.log(e.target.id);
    let location = e.target.id;


    if (location === 'login'){
      navigate('/login', { replace: true })
      //history.push("/addsource");
  }   


    if (location === 'profile'){
      navigate('/profile', { replace: true })
      //history.push("/addsource");
  }   

  if (location === 'signup'){
    navigate('/signup', { replace: true })
    //history.push("/addsource");
  }

  if (location === 'signout'){
    navigate('/signout', { replace: true })
    //history.push("/addsource");
  }
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };



  /*
  const newsmenuId = 'news-account-menu';
  const renderNewsMenu = (
    <Menu
      anchorEl={anchorEl2}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={newsmenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>News Sources</MenuItem>
      <MenuItem onClick={handleMenuClose}>Add News Sources</MenuItem>
     
    </Menu>
  );
*/





  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >

      { !LoggedIn &&
                <MenuItem id="login" onClick={handleMenuClose}>Login</MenuItem>
      }
      { !LoggedIn &&
                <MenuItem id="signup" onClick={handleMenuClose}>SignUp</MenuItem>
      }
        
     { LoggedIn &&


      <MenuItem id="profile" onClick={handleMenuClose}>Profile</MenuItem>
     }
     
     { LoggedIn && 
      <MenuItem id="signout" onClick={()=>{

            console.log('signing out')
            dispatch(AccessData({AccessToken:  '',  isAuthenticated: false}));
            setLoggedIn(false);

      }}>Signout</MenuItem>
     }
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >

      {/*
      <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="error">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge badgeContent={17} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      */}
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  const count = useSelector((state) => state.counter.value);
  const filter = useSelector((state) => state.filter.value);
  const mystories = useSelector((state) => state.mystories.value);
  const stats = useSelector((state) => state.stats.value);
  const authstate = useSelector((state) => state.authstate.value);
  const mysources = useSelector((state) => state.mysources.value);

  const dispatch = useDispatch()


  const [SearchTerm, setSearchTerm] = React.useState(null);  
  const [MyColor, setMyColor] = React.useState('inherit');
 

  const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);

  useEffect(() => {


      console.log(filter);
      if(filter == 0){
      setMyColor('error');
      }
      else{
        setMyColor('inherit');
        
      }

  },[filter]);


  
  useEffect(() => {


    let AccessToken = authstate.AccessToken;
    let isAuthenticated = authstate.isAuthenticated;
    let username = authstate.username;


    let MySourceZ = mysources;
    console.log("PP: " + JSON.stringify(MySourceZ));


    if(isAuthenticated){
      setLoggedIn(true);
      setUserName(username);
      
    }
    else{
      setLoggedIn(false);
      setUserName();
    }

    console.log("AA" + JSON.stringify(authstate));
    
});

const [open, setOpen] = React.useState(false);
  const [anchorEl3, setAnchorEl3] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl3(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
    console.log('here');
  };

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? 'transition-popper' : undefined;





  return (
    <Box sx={{ flexGrow: 1 }}>
      
      <AppBar position="fixed"  sx={{bgcolor: '#2E3B55'}}>
   
        <Toolbar>


        <SwipeableTopicDrawer></SwipeableTopicDrawer>

        
          { authstate.username == 'zs1rcm' &&
          <NewsMenu></NewsMenu>

          }
        

          
     
          {/*
          <Typography
            variant="h10"
            noWrap
            component="div"
           
            sx={{ display: { xs: 'none', sm: 'block' } }}
          >
           newsy&nbsp;&nbsp;
          </Typography>

          */}
   



          <IconButton
            size="small"
            //edge="start"
            color="inherit"
            aria-label="open drawer"
            //sx={{ mr: 2 }}
            onClick={()=>{
              document.location.href = '/topstories';
            }
            }

          >
            <Tooltip title="Top Stories">

          <RemoveRedEyeIcon></RemoveRedEyeIcon>
          </Tooltip>
          </IconButton>


     
        <IconButton size="small" 
                    aria-label="filter" 
                    onClick={(()=>{
                        console.log('Filter');
                        dispatch(increment());

                        if(filter == 0){
                            dispatch(on());
                            setMyColor('inherit');
                        }
                        else if(filter == 1){
                            dispatch(off());
                            setMyColor('error');
                        }
                      
                        console.log(filter);
                        
                    })}
                    
                    color={MyColor}> 

          <Badge badgeContent={0} color="error">
          <Tooltip title="Sentiment Filter">
            <FilterListIcon />
            </Tooltip>
          </Badge>
        </IconButton>
                  

                    
                     [ {stats.NumStories} ]
                    

        <IconButton size="large" 
                    aria-label="refresh" 
                    onClick={(()=>{
                        console.log('Refreshing Stories');


                        //Refresh Stories
                        dispatch(refresh());
                        

                        /*
                        if(filter == 0){
                            dispatch(on());
                        }
                        else if(filter == 1){
                            dispatch(off());
                        }
                        */
                      
                 
                        
                    })}
                    color="inherit"> 

          <Badge badgeContent={0} color="error">
            <RefreshIcon />
          </Badge>
        </IconButton>
          
          <Box sx={{ flexGrow: 1 }} />
          {UserName}
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>



           
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
              
            </IconButton>
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Offset />
      {renderMobileMenu}
      {renderMenu}
  
    </Box>
  );
}