import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';


import { useSelector, useDispatch } from 'react-redux';

import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import SearchIcon from '@mui/icons-material/Search';



import { TopicData } from '../redux/TopicSlice';





export default function SwipeableTopicDrawer() {



    let server_url = process.env.REACT_APP_SERVER_URL;


  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });



  const[Topics, setTopics] = React.useState([]);


  const dispatch = useDispatch()
  const myTopic = useSelector((state) => state.TopicData.value);




  useEffect(()=>{
 
    console.log('testing AddSrouce');

    
    let source_data = gdata();
    //console.log(source_data);

  }, []);



  async function gdata(){

    //let data = await get_data();
    let  data =  await fetch_cached_topics();

    let newArray = new Array();
    data.forEach(element => {

        let tname = element.topic_name;
        newArray.push(tname);
        
    });
    setTopics(newArray);
    console.log("NArray:" + newArray);
    console.log(data);

}




async function fetch_cached_topics(){


    //let server_url = 'http://127.0.0.1:3401';
    return new Promise(function (resolve,reject){
  
      try{
                      //fetch(server_url + "/stories?timestamp='" + timestamp + "'")
                      fetch("/static/json/topics.json")
                      .then(response => response.json())
                      .then(function(data){
                              
                              //console.log(data);
                              resolve(data);
                      
                      });
      }
      catch(err){
              console.log(err);
              reject(err);
      }
  
      })
  
  
  
  }


  async function get_data(){
  
  
    return new Promise(function (resolve,reject){

      try{

                /*
                const token = localStorage.getItem('AccessToken'); 
                let options = {
                  headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                  },
                  insecure: true,
                  rejectUnauthorized: false
                }

                let myheaders = {
                  'Authorization': 'Bearer ' + token,
                  'Content-Type': 'application/json'
                };
                */

                let myheaders = {
                  'Content-Type': 'application/json'
                };

                  
        
                  fetch(server_url + "/get_topics",{
                    method: 'GET',
                    //headers: { 'Content-Type': 'application/json' },
                    headers: myheaders,
                    //body: JSON.stringify(dataObject)
                    })
                      .then(response => response.json())
                      .then(function(data){
                              console.log('xxx');
                              console.log(data);
                              console.log('xxx2');
                              setTopics(data);
                              resolve(data)
                              //resolve(data);
                      
                      });
      }
      catch(err){
              console.log(err);
              reject(err);
      }

      })




  }

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
       {/* {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (*/}

       {
      
       Topics.map((text,index)=>(
          <ListItem button key={text} onClick={(data)=>{
                console.log('clicked: ' + text);
                dispatch(TopicData(text));
                //window.location.reload();
                window.location.href = '/';
        
          }}>
            <ListItemIcon>
              {index % 2 === 0 ? <ArrowCircleRightIcon /> : <DoubleArrowIcon />}
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>

        {/*
        {['Search', 'Login/LogOut', 'About'].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>
              {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
        */}

        <ListItem button key='search' onClick={(data)=>{
                console.log('clicked: ' + data);
                //dispatch(TopicData(text));
                //window.location.reload();
                window.location.href = '/search';
          }}>
        
        
            <ListItemIcon>

              <SearchIcon></SearchIcon>
              
            </ListItemIcon>
            <ListItemText primary='Search' />
          </ListItem>

      </List>
    </Box>
  );

  return (
    <div>
      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
          {/* <Button onClick={toggleDrawer(anchor, true)} style={{ color: "#fff", textDecoration: "none" }}> */}
          <IconButton
            size="small"
            //edge="start"
            color="inherit"
            aria-label="open drawer"
            //sx={{ mr: 2 }}
            onClick={toggleDrawer(anchor, true)}

          >
            <Tooltip title="Choose a Category">
            <MenuIcon />
            </Tooltip>
          </IconButton>


          {/*  </Button> */}
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}
