import { configureStore } from '@reduxjs/toolkit'

import storage from 'redux-persist/lib/storage'
import {combineReducers} from "redux"; 
import { persistReducer } from 'redux-persist'
import thunk from 'redux-thunk'




import counterReducer from './counterSlice'
import filterReducer from './filterSlice'
import storyReducer from './storySlice'
import statsReducer from './statsSlice'
import authSliceReducer from './authSlice'
import mySourcesSliceReducer from './mySourcesSlice';
import TopicSliceReducer, { TopicData } from './TopicSlice';


const reducers = combineReducers({
  authstate: authSliceReducer,
  counter: counterReducer,
  filter: filterReducer,
  mystories: storyReducer,
  stats: statsReducer,    
  mysources: mySourcesSliceReducer,
  TopicData: TopicSliceReducer
 });


const persistConfig = {
  key: 'root',
  version: 1,
  storage,
}

const persistedReducer = persistReducer(persistConfig, reducers)



export const store = configureStore({
    reducer: persistedReducer,
    //devTools: process.env.NODE_ENV !== 'production',
    middleware: [thunk]
})