import React, { useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import FilterListIcon from '@mui/icons-material/FilterList';
import Tooltip from '@mui/material/Tooltip';
import { styled, alpha } from '@mui/material/styles';

import moment, { ISO_8601 } from 'moment';


import { useSelector, useDispatch } from 'react-redux';
import { decrement, increment } from '../redux/counterSlice';
import { refresh, clear } from '../redux/storySlice';
import { setStats } from '../redux/statsSlice';
import { AccessData } from '../redux/authSlice';
import { TopicData } from '../redux/TopicSlice';
import { updateMySources } from '../redux/mySourcesSlice';
import { CandlestickChart } from '@mui/icons-material';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@material-ui/core';
import Checkbox from '@mui/material/Checkbox';





const SearchX = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  }));
  
  const SearchIconWrapperX = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));
  
  const StyledInputBaseX = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
  }));
  
const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));



//let server_url = "https://mapi2.newsy.today";
//let server_url = "http://127.0.0.1:3404";
let server_url = process.env.REACT_APP_SERVER_URL;







async function fetch_searchit(searchterm, timestamp){


  //let server_url = 'http://127.0.0.1:3401';
  return new Promise(function (resolve,reject){

    try{
                    fetch(server_url + "/searchit?searchterm=" + searchterm + "&timestamp=" + timestamp)
                    .then(response => response.json())
                    .then(function(data){
                            
                            //console.log(data);
                            resolve(data);
                    
                    });
    }
    catch(err){
            console.log(err);
            reject(err);
    }

    })



}




const StoriesList = (props) => { 


  const [CurrentTime,setCurrentTime] = useState();
  const [CurrentTimestamp,setCurrentTimestamp] = useState();
  const [RunReload, setRunReload] = useState("run");
  const [Stories, setStories] = useState([]);
  const [NumStories, setNumStories] = useState(0);

  const [StoriesGrouped, setStoriesGrouped] = useState([]);
  const [NumStoriesGrouped, setNumStoriesGrouped] = useState(0);

  const [Sources,setSources] = useState([]);
  
  const [TopKeywords, setTopKeywords] = useState([]);

  const [ShowText, setShowText] = useState(false);
  const [SentimentVal,setSentimentVal] = useState(2);

  const [SearchTerm, setSearchTerm] = useState();
  const [SearchTimeStamp, setSearchTimeStamp] = useState(0);

  const [Topic, setTopic] = useState();

  const count = useSelector((state) => state.counter.value);
  const filter = useSelector((state) => state.filter.value);
  const stats = useSelector((state) => state.filter.value);
  const dispatch = useDispatch()

  const authstate = useSelector((state) => state.authstate.value);
  const mysources = useSelector((state) => state.mysources.value);


  const myTopic = useSelector((state) => state.TopicData.value);

 


 
  useEffect(() => {

    if(filter == 1){
      setShowText(true);
    }
    else{
      setShowText(false);
    }
   

    

  });



   
  useEffect(() => {

 
    console.log('sources changed: reloading news');
    setRunReload("run");


  },[mysources]);


  useEffect(() => {

 
   // console.log('sources changed: reloading news');
    setRunReload("run");

  },[Topic]);


/*
  useEffect(() => TopicData.subscribe(() => {
    const jsonValue = TopicData.getState();
    //setJsonVal(jsonValue)
    console.log(jsonValue)
    })
   , [TopicData]);
   
 */


  //let jdata = props.jdata;
  //let jjdata = JSON.parse(jdata);

  //console.log('ppp: ' + JSON.stringify(props));

  console.log("PP: 4" + (props.jdata.length));
  let wjson = props.jdata;
  let stories = new Array();
  let i = 0; const iMax = wjson.length -1; for(; i <= iMax; i++){
      //if(parseInt(wjson[i].sentiment) <= -4){
      
      stories.push({title: wjson[i].title, firstseen: wjson[i].firstseen, lastseen: wjson[i].lastseen, sentiment: wjson[i].sentiment, hash: wjson[i].hash, url: wjson[i].url, feed_title: wjson[i].feed_title, tags: wjson[i].web});
      //}
  }
  //console.log(props.jdata.length);

  //console.log(jdata);




  const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  }));
  
  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));
  
  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
  }));





  

  
  return (
    <div className="trips">



      {stories.map(function(item, i){   
      //let url = 'https://lms-api.isnet.co.za/maps/?tripid=' + item.tripid;
      let timeSeconds = Math.round((moment().unix() - parseInt(item.firstseen))/60);

     
     // let bgColor =  "#f0f2f5";
     const styleObj = {

      fontSize: 11,
      color: "grey",
      textAlign: "left",
      paddingLeft: "10px",
      paddingTop: "0px",
      textDecoration: 'none',
      //backgroundColor: "#f0f2f5"
  }

  const storyObj = {

    fontSize: 14,
    fontWeight: "bold",
    //color: "#4a54f1",
    color: "#3D4849",
    textAlign: "left",
    paddingLeft: "10px",
    paddingTop: "0px",
    textDecoration: 'none',
    //backgroundColor: "#f0f2f5"

    
}
  
let tagtype = "neutral";
if(item.sentiment < 0){
  tagtype = "bad";
}
if(item.sentiment > 0){
  tagtype = "good";
}



     let bgColor = "";
      if (i % 2 == 0){ 

       
       
     // console.log('Number is even');


      bgColor =  "#f0f2f5";
      styleObj.backgroundColor = "#f0f2f5";
      storyObj.backgroundColor = "#f0f2f5";

        //red
        if(item.sentiment <= -4){
          styleObj.backgroundColor = "#FFC9C9";
          storyObj.backgroundColor = "#FFC9C9";
        }
        //green
        if(item.sentiment >= 4){
          styleObj.backgroundColor = "#CAFFC9";
          storyObj.backgroundColor = "#CAFFC9";
        }
           
            

            if(ShowText){
              return(
            
                
            
                <Card variant="outlined"  sx={{ minWidth: 400 }} >
                    <dl style={storyObj} key={i} tagtype={tagtype}>
                      <dt style={storyObj}><a href={item.url} style={{ textDecoration: 'none',  color: "#3D4849"}} target="_blank">{item.title}</a></dt>
                      <dt style={styleObj}><b>Sentiment:</b> {item.sentiment} | <b>Age</b>:{timeSeconds} minutes ago | <b>Feed</b>: {item.feed_title}</dt>
                    </dl> 
                </Card>
                  
                )
              }
              else{

                if(item.sentiment < -SentimentVal){
                  return(

                    <Card variant="outlined"  sx={{ minWidth: 400 }} >
                   
                    <dl style={storyObj} key={i} tagtype={tagtype}>
                      <dt style={storyObj}><a href={item.url} style={{ textDecoration: 'none',  color: "#3D4849"}} target="_blank">{item.title}</a></dt>
                      <dt style={styleObj}><b>Sentiment:</b> {item.sentiment} | <b>Age</b>:{timeSeconds} minutes ago | <b>Feed</b>: {item.feed_title}</dt>
                    </dl> 
                    </Card>


                  );
                }

                if(item.sentiment > SentimentVal){
                  return(

                    <Card variant="outlined"  sx={{ minWidth: 400 }} >
                    <dl style={storyObj} key={i} tagtype={tagtype}>
                      <dt style={storyObj}><a href={item.url} style={{ textDecoration: 'none',  color: "#3D4849"}} target="_blank">{item.title}</a></dt>
                      <dt style={styleObj}><b>Sentiment:</b> {item.sentiment} | <b>Age</b>:{timeSeconds} minutes ago | <b>Feed</b>: {item.feed_title}</dt>
                    </dl> 

                    </Card>
                  );
                }

              }

      }
      else{



      //console.log("Number is odd");
        bgColor = "#FFFFFF";
        storyObj.backgroundColor = "#FFFFFF";
        styleObj.backgroundColor = "#FFFFFF";



        if(item.sentiment <= -4){
          styleObj.backgroundColor = "#FFC9C9";
          storyObj.backgroundColor = "#FFC9C9";
        }
        if(item.sentiment >= 4){
          styleObj.backgroundColor = "#CAFFC9";
          storyObj.backgroundColor = "#CAFFC9";
        }




          if(ShowText){
            return(
          
              
          
                  <dl style={storyObj} key={i} tagtype={tagtype}>
                    <dt style={storyObj}><a href={item.url} style={{ textDecoration: 'none',  color: "#3D4849"}} target="_blank">{item.title}</a></dt>
                    <dt style={styleObj}><b>Sentiment:</b> {item.sentiment} | <b>Age</b>:{timeSeconds} minutes ago | <b>Feed</b>: {item.feed_title}</dt>
                  </dl> 
        
                
              )
            }
            else{

              if(item.sentiment < -SentimentVal){
                return(

                 
                  <dl style={storyObj} key={i} tagtype={tagtype}>
                    <dt style={storyObj}><a href={item.url} style={{ textDecoration: 'none',  color: "#3D4849"}} target="_blank">{item.title}</a></dt>
                    <dt style={styleObj}><b>Sentiment:</b> {item.sentiment} | <b>Age</b>:{timeSeconds} minutes ago | <b>Feed</b>: {item.feed_title}</dt>
                  </dl> 


                );
              }

              if(item.sentiment > SentimentVal){
                return(

                 
                  <dl style={storyObj} key={i} tagtype={tagtype}>
                    <dt style={storyObj}><a href={item.url} style={{ textDecoration: 'none',  color: "#3D4849"}} target="_blank">{item.title}</a></dt>
                    <dt style={styleObj}><b>Sentiment:</b> {item.sentiment} | <b>Age</b>:{timeSeconds} minutes ago | <b>Feed</b>: {item.feed_title}</dt>
                  </dl> 


                );
              }
            }

  
      }

 



      })}
    
    </div>
  )
  

 
  /*
 return(
  <li>test</li>

 )
 */
}






function SearchR (){




  const [CurrentTime,setCurrentTime] = useState();
  const [CurrentTimestamp,setCurrentTimestamp] = useState();
  const [RunReload, setRunReload] = useState("run");
  const [Stories, setStories] = useState([]);
  const [NumStories, setNumStories] = useState(0);

  const [StoriesGrouped, setStoriesGrouped] = useState([]);
  const [NumStoriesGrouped, setNumStoriesGrouped] = useState(0);

  const [Sources,setSources] = useState([]);
  
  const [TopKeywords, setTopKeywords] = useState([]);

  const [ShowText, setShowText] = useState(false);
  const [SentimentVal,setSentimentVal] = useState(2);

  const [SearchTerm, setSearchTerm] = useState();
  const [SearchTimeStamp, setSearchTimeStamp] = useState(0);

  const [Topic,setTopic] = useState();
 

  const mystories = useSelector((state) => state.mystories.value);
  const stats = useSelector((state) => state.stats.value);

  const dispatch = useDispatch()


  const mysources = useSelector((state) => state.mysources.value);


  const myTopic = useSelector((state) => state.TopicData.value);


  const [ShowSearch,setShowSearch] = useState(false);

 
 useEffect(() => {





  if(mystories == 1){
    setRunReload("run");
  }    


  if(mystories.term){

    console.log("loading: " + mystories.term);
    setSearchTerm(mystories.term);
    dispatch(clear());
    search2(mystories.term);

    

  }

  
      
        
  if(RunReload == "run"){

    console.log('useEffect Run Reload called');

    //dispatch(updateMySources("enabled"));
  

    /*
            async function get_topkeywords(){

              


        

                    let keywords = await fetch_top_keywords();

                

                    console.log(keywords)
                    setTopKeywords(keywords);

                    
            }
                    

            get_topkeywords();
            */



            async function get_stories(topic){

              console.log('updating stories1:' + topic);


              if(topic.length <= 0){
                topic = "NEWS";
              }

              console.log("Sources:" + JSON.stringify(mysources));


              let mytimestamp = moment().unix() - 14400;

            //setCurrentTimestamp(moment().unix() - 3600); //Last hour


              // let stories = await fetch_stories(mytimestamp);
             // let stories = await fetch_cached_stories(mytimestamp);
             let stories = {};
              //console.log("PP: 5" + stories.length);


              //Iterate stories and filter our ones not selected

              let filtered_stories = new Array();

              try{
                if(Object.keys(mysources).length > 0){
                  console.log('z21');
                  console.log(mysources);


                  //Lookup Sources web

                  

                  let i=0; const iMax = stories.length - 1; for(; i<=iMax; i++){

                          //topic = 'NEWS';


                          let src = stories[i].source;
                          if(mysources[src] == true){
                             console.log('z21:' + JSON.stringify(stories[i]));
                             //setTopic('TECH');
                       

                             

                             let tags = stories[i].web;
                             console.log("TAGS:" + tags);

                             if(topic){
                              if(tags.includes(topic)){

                              filtered_stories.push(stories[i]);
                              }
                             }
                             else{
                              filtered_stories.push(stories[i]);
                             }

                          }
                          else{
                            let tags = stories[i].web;
                            if(tags.includes(topic)){
                            filtered_stories.push(stories[i]);
                            }

                          }

                          if(i == iMax){

                            stories = filtered_stories;
                          }
                  }
                }
                else{
                            //Do nothing
                            console.log("SSSOURCES NOT SET....");


                            let i=0; const iMax = stories.length - 1; for(; i<=iMax; i++){

                              //topic = 'NEWS';
    
    
                              let src = stories[i].source;
           
                                let tags = stories[i].web;
                                if(tags.includes(topic)){
                                filtered_stories.push(stories[i]);
                                }
    
                              
    
                              if(i == iMax){
    
                                stories = filtered_stories;
                              }
                      }
                }
              }
              catch(err){

                console.log(err);
                //do nothing
              }

              //let newStories = new Array();
              //let x=0; const xMax = stories.length - 1; for(; x<=xMax; x++){


                //    let ischecked = mysources[stories[x].source];
                //    console.log("PP: 5 isChecked: " + ischecked);

                //    if(ischecked == true){

                //      newStories.push(stories[x]);

                 //   }

                    //if(x==xMax){

                      setStories(stories);
                      setNumStories(stories.length);

                      dispatch(setStats({NumStories: stories.length}));

                      dispatch(clear());


                    //}



             // }


              

            }

            //dispatch(TopicData("TECH"));

            console.log("MMM: " + myTopic);

            get_stories(myTopic);




/*

            async function get_stories_grouped(){

              console.log('updating stories');


              let mytimestamp = moment().unix() - 14400;

            //setCurrentTimestamp(moment().unix() - 3600); //Last hour


              let stories = await fetch_stories_grouped(mytimestamp);
              console.log(stories);
              setStoriesGrouped(stories);
              setNumStoriesGrouped(stories.length);

            }
            get_stories_grouped();

*/

/*

            async function get_sources(){

              console.log('updating sources');
              let mysources = await fetch_sources();

              setSources(mysources);
              console.log(mysources);
         

            }
            get_sources();
            */


            setRunReload(false);


      }
     
        
      
    


});


useEffect(() => {

 
  console.log('Topic changed: reloading news');
  setRunReload("run");

}, [TopicData]);




async function search(){

  let stories = await fetch_searchit(SearchTerm, SearchTimeStamp);

  //let stories = await fetch_stories(mytimestamp);
  //console.log(stories);
  if(stories){
      console.log('Stories Finished..');
      setShowSearch(false);
  }
  setStories(stories);
  setNumStories(stories.length);

  console.log(stories);

}

async function search2(searchTerm){

  let stories = await fetch_searchit(searchTerm, SearchTimeStamp);

  //let stories = await fetch_stories(mytimestamp);
  //console.log(stories);
  setStories(stories);
  setNumStories(stories.length);
  dispatch(setStats({NumStories: stories.length}));


  console.log(stories);

}







    


    return (
    
      <div>

          {/*
        <div>{myTopic}</div>
      <StoriesList jdata={Stories}></StoriesList>
          */}

          Search News Stories Below


          <Search>
            <SearchIconWrapper>
     
            </SearchIconWrapper>
            <StyledInputBase
              border="1"
              placeholder="Search…"
              inputProps={{ 'aria-label': 'search' }}
               onChange={(e)=>{

                    //console.log(e.target.value);
                    //console.log("fff");
                  setSearchTerm(e.target.value); 
    
              }}
              
            >
                  </StyledInputBase>
                  <IconButton size="small" aria-label="search" color="default">
                    <Badge badgeContent={0} color="error">
                        <SearchIcon onClick={(e)=>{


                            setShowSearch(true);
                            console.log('Searching:' + SearchTerm);
                            dispatch(search({'term': SearchTerm}));
                            //handleClick(e);


                        }}/>
                    </Badge>
                    </IconButton>
                    
          
              
          </Search>

            {ShowSearch
             ?   <div> Busy Searching .... </div>
             :      <div></div>
            }
          <StoriesList  jdata={Stories}></StoriesList>




      </div>
    );
   }
    
   export default SearchR;