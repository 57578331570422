import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Button from '@mui/material/Button';

import { DataGrid } from '@mui/x-data-grid';




let server_url = 'https://mapi2.newsy.today';




function ViewSources (){



    const [Sources,setSources] = useState([]);

    const [RowData, setRowData] = useState([]);
    const [RowKeys, setRowKeys] = useState([]);
    const [ColumnData, setColumnData] = useState([]);

    useEffect(()=>{
 
        console.log('testing AddSrouce');

        
        let source_data = gdata();
        //console.log(source_data);
    
      }, []);



      async function gdata(){

        let data = await get_data();
        setSources(data);
        console.log(data);

    }




    //Set RowIDS
    useEffect(()=>{


      setRowData(Sources);
      setRowKeys(Object.keys(Sources));
  
      //Setup Headers
      let toplen = 0;
  
      let myObject = new Object;
      let rowArray = new Array;
      console.log("L: " + Sources.length);
      let x=0; const xMax = Sources.length -1; for(; x<=xMax; x++){
  
            Sources[x]['id'] = x; //Add id key to each field
  
  
            let mykeys = Object.keys(Sources[x]);
  
           
           
            //Add Headers
           mykeys.forEach((item) => {
           let headerdata = {
              field: item,
              headerName: item,
              width: 120,
              editable: true,
              
            };
  
            myObject[item] = headerdata;
  
            //Add Rows
            //{ id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
            //console.log(jdata[x]);
            
  
  
  
  
          })
  
          if(x == xMax){
            
            
         
  
             //add custom columns
             myObject["note"] = {
              field: "Note",
              headerName: "Note",
              width: 150,
              editable: true,
            };
  
            myObject["ledgeramount"] = {
              field: "LedgerAmount",
              headerName: "Ledger Amount",
              width: 180,
              editable: true,
            };

           
  
  
            let items = Object.keys(myObject);
            let colArray = new Array();
            let z=0; const zMax = items.length - 1; for(; z <= zMax; z++){
                colArray.push(myObject[items[z]]);

              
  
                if(z===zMax){
  
                  console.log(colArray);
                  setColumnData(colArray);
                }
  
              }
            
             
  
  
  
            }
  
  
          }
  
           
      
  
  
  



    }, [Sources]);




    async function update_sources(sourcesJson){
  
  
      return new Promise(function (resolve,reject){

        try{

                  /*
                  const token = localStorage.getItem('AccessToken'); 
                  let options = {
                    headers: {
                      'Authorization': 'Bearer ' + token,
                      'Content-Type': 'application/json'
                    },
                    insecure: true,
                    rejectUnauthorized: false
                  }

                  let myheaders = {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                  };
                  */

                  let myheaders = {
                    'Content-Type': 'application/json'
                  };

                    
                 
                    fetch(server_url + "/update_sources",{
                      method: 'POST',
                      //headers: { 'Content-Type': 'application/json' },
                      headers: myheaders,
                       body: JSON.stringify(sourcesJson)
                      })
                        .then(response => response.json())
                        .then(function(data){
                                console.log('xxx');
                                console.log(data);
                                console.log('xxx2');
                                setSources(data);
                                resolve(data)
                                //resolve(data);
                        
                        });
        }
        catch(err){
                console.log(err);
                reject(err);
        }

        })

      }


      async function get_data(){
  
  
        return new Promise(function (resolve,reject){

          try{

                    /*
                    const token = localStorage.getItem('AccessToken'); 
                    let options = {
                      headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                      },
                      insecure: true,
                      rejectUnauthorized: false
                    }

                    let myheaders = {
                      'Authorization': 'Bearer ' + token,
                      'Content-Type': 'application/json'
                    };
                    */

                    let myheaders = {
                      'Content-Type': 'application/json'
                    };

                      
            
                      fetch(server_url + "/get_data",{
                        method: 'GET',
                        //headers: { 'Content-Type': 'application/json' },
                        headers: myheaders,
                        //body: JSON.stringify(dataObject)
                        })
                          .then(response => response.json())
                          .then(function(data){
                                  console.log('xxx');
                                  console.log(data);
                                  console.log('xxx2');
                                  setSources(data);
                                  resolve(data)
                                  //resolve(data);
                          
                          });
          }
          catch(err){
                  console.log(err);
                  reject(err);
          }

          })




      }

      const handleGetRowId = (e) => {
        return e.sourceid
    }


      const columns = [
        { field: 'sourceid', headerName: 'ID', width: 90 },
        {
          field: 'cnt',
          headerName: 'Country',
          width: 150,
          editable: true,
        },
        {
          field: 'code',
          headerName: 'Code',
          width: 150,
          editable: true,
        },
        {
            field: 'name',
            headerName: 'Name',
            width: 150,
            editable: true,
          },
          {
            field: 'status',
            headerName: 'Status',
            width: 150,
            editable: true,
          },
          {
            field: 'url',
            headerName: 'URL',
            width: 150,
            editable: true,
          },
          {
            field: 'web',
            headerName: 'WEb',
            width: 150,
            editable: true,
          }
        ]
    


        const handleCellEditCommit = (updateitem) => {

          console.log('hERE');
  
          console.log(updateitem);
      
          let id = updateitem.id;
      
          let newRowData = new Array();
      
          let i=0; const iMax = RowData.length -1; for(; i <= iMax; i++){
      
            let item = RowData[i];
            let cid = item.id;
            if(id == cid){
      
              console.log('Match');
              //console.log(row);
              console.log(item);
              let field = updateitem.field;
              let value = updateitem.value;
              item[field] = value;
              console.log(updateitem);
              
              //update Row witn new Row
              newRowData.push(item);
      
            } 
            else{
      
              newRowData.push(item);
            }
    
           
      
            if(i === iMax){
              console.log('Setting New RowData');
              setRowData(newRowData);
              console.log(newRowData);
            }

          }
      
      }
        

      const [editRowsModel, setEditRowsModel] = React.useState({});

      const handleEditRowsModelChange = React.useCallback((model) => {
        console.log('yes');
        setEditRowsModel(model);
        console.log(model);
      }, []);


    return(

    <div>

      <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        id="dataTable"
        stateChange={handleCellEditCommit}
        onRowEditStop={handleCellEditCommit}
        rows={RowData}
        columns={ColumnData}
        pageSize={50}
        //rowsPerPageOptions={[5]}
        //checkboxSelection
        //disableSelectionOnClick
        checkboxSelection={true}
        editRowsModel={editRowsModel}
        onEditRowsModelChange={handleEditRowsModelChange}
        onCellEditCommit={handleCellEditCommit}

      
        //getRowId={handleGetRowId}
      />
    </div>


    <div>
    <Button variant="text" onClick={()=>{

        

        console.log(RowData);

        
        update_sources(RowData);
        

/*
        let code = document.getElementById('code').value;
        let feed = document.getElementById('feedname').value;
        let url = document.getElementById('url').value;
        let status = document.getElementById('status').value;
        console.log(code + " " + feed + " " + url);

        let jsonData = {
        code: code,
        feed: feed,
        url: url,
        status: status
        }
        */

       // update_data(jsonData);


    }}>save</Button>          
            
    </div>
    </div>



    );
   }
    
   export default ViewSources;