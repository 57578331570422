import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: 0,
}

export const TopicSlice = createSlice({
  name: 'topicstate',
  initialState,
  reducers: {
    TopicData: (state, action) => {
      /*
        {AccessToken: '',  isAuthenticated: false, username: "username"}
      */
      state.value = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { TopicData } = TopicSlice.actions

export default TopicSlice.reducer