import { BrowserRouter as Router,Routes, Route, Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { AppBar, Tab, Tabs, TextField } from '@material-ui/core';


import PrimarySearchAppBar from './templates/appbar';

import Home from './templates/home';
import AddSource from './templates/addsource';
import ViewSources from './templates/viewsources';
import Topics from './templates/topics';
import SignUp from './templates/signup';
import Login from './templates/login';
import MySources from './templates/mysources';
import TopStories from './templates/topstories';
import ViewTopics from './templates/view_topic';

import Search from './templates/search';




import ReactGA from 'react-ga';




import './App.css';


ReactGA.initialize("UA-162976163-1");


ReactGA.pageview(window.location.pathname + window.location.search);


function App() {


const hstyleObj = {

  fontSize: 14,
  color: "white",
  fontWeight: 'bold',
  textAlign: "center",
  //paddingLeft: "10px",
  paddingTop: "0px",




}

const wordstyleObj = {

  fontSize: 8,
  color: "grey",
  fontWeight: 'bold',
  textAlign: "center",
  //paddingLeft: "10px",
  paddingTop: "0px",




}

  return (

    <Router>
    <div className="App">
 
    <PrimarySearchAppBar ></PrimarySearchAppBar>

      <Routes>
                <Route exact path='/' element={< Home />}></Route>
                <Route exact path='/mysources' element={< MySources />}></Route>
                <Route exact path='/topics' element={< Topics />}></Route>
                <Route exact path='/addsource' element={< AddSource />}></Route>
                <Route exact path='/viewsources' element={< ViewSources />}></Route>
                <Route exact path='/signup' element={< SignUp />}></Route>
                <Route exact path='/login' element={< Login />}></Route>
                <Route exact path='/topstories' element={< TopStories />}></Route>
                <Route exact path='/view_topic' element={< ViewTopics />}></Route>
                <Route exact path='/search' element={< Search />}></Route>
                {/* <Route exact path='/contact' element={< Contact />}></Route> */}
      </Routes>

    </div>
    </Router>
  );
}

export default App;
