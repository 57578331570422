import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: 0,
}

export const authSlice = createSlice({
  name: 'authstate',
  initialState,
  reducers: {
    AccessData: (state, action) => {
      /*
        {AccessToken: '',  isAuthenticated: false, username: "username"}
      */
      state.value = action.payload
    },
    loggedin: (state) => {
        state.value = 1
    },
    loggedout: (state) => {
      state.value = 0
    }
  },
})

// Action creators are generated for each case reducer function
export const { loggedin, loggedout, AccessData } = authSlice.actions

export default authSlice.reducer