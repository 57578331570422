import React, { useEffect } from 'react';

import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Button from '@mui/material/Button';

import {useNavigate} from 'react-router-dom'

import sha512 from 'js-sha512';


//Redux
import { useSelector, useDispatch } from 'react-redux'
import { AccessData } from '../redux/authSlice';
import { updateMySources } from '../redux/mySourcesSlice';
//import { isPlainObject } from 'immer/dist/internal';


//let server_url = 'https://mapi2.newsy.today';
//let server_url = 'http://127.0.0.1:3404';
let server_url = process.env.REACT_APP_SERVER_URL;




function Login (){


    const [Status,setStatus] = React.useState();

    const dispatch = useDispatch();

    const navigate = useNavigate();



    useEffect(()=>{
 
        console.log('Signup');
        
    
      });


      async function login_user(dataObject){
  
  
        return new Promise(function (resolve,reject){

          try{

                    /*
                    const token = localStorage.getItem('AccessToken'); 
                    let options = {
                      headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                      },
                      insecure: true,
                      rejectUnauthorized: false
                    }

                    let myheaders = {
                      'Authorization': 'Bearer ' + token,
                      'Content-Type': 'application/json'
                    };
                    */

                    let myheaders = {
                      'Content-Type': 'application/json'
                    };

                      
            
                      fetch(server_url + "/login",{
                        method: 'POST',
                        //headers: { 'Content-Type': 'application/json' },
                        headers: myheaders,
                        body: JSON.stringify(dataObject)
                        })
                          .then(response => response.json())
                          .then(function(data){
                                  console.log('xxx');
                                  console.log(data);
                                  console.log('xxx2');
                                  resolve(data);
                               
                          
                          });
          }
          catch(err){
                  console.log(err);
                  reject(err);
          }

          })




      }



      async function fetch_mysources(username){


        //let server_url = 'http://127.0.0.1:3401';
        return new Promise(function (resolve,reject){
      
          try{

                          console.log("checking: " + username)
                          fetch(server_url + "/get_user_sourcelist?username=" + username)
                          .then(response => response.json())
                          .then(function(data){
                                  
                                
                                  console.log("data:" + data);
                                  resolve(data);
                          
                          });
          }
          catch(err){
                  console.log(err);
                  reject(err);
          }
      
          })
      
      
      
      }
    


    return(


      <Box sx={{ '& > :not(style)': { m: 1 } }}>
      <FormControl variant="standard">


      <div>
        <TextField
          label="UserName"
          id="username"
          defaultValue=""
          size="small"
          margin="dense"
        />
      </div>
          

      <div>
        <TextField
          label="Password"
          id="password"
          type="password"
          defaultValue=""
          size="small"
          margin="dense"
        />
       
      </div>
       


       
   


      <div>
      <Button variant="text" onClick={async ()=>{

            let username = document.getElementById('username').value;
            let password = document.getElementById('password').value;
           
            let hashed_pass = sha512(password);


            let jsonData = {
              username: username,
              password: hashed_pass,
 
            }

            console.log(jsonData);
            let res = await login_user(jsonData);
            if(res.token){
                setStatus('User Logged IN');
                dispatch(AccessData({AccessToken:  res.token,  isAuthenticated: true , username: username}));

                let mysources = await fetch_mysources(username);

                console.log("VVVV:" + typeof mysources);
                //process.exit();

                if( (typeof mysources === "object" )){

                    console.log("VVV:"  + JSON.stringify(mysources));

                    dispatch(updateMySources(mysources));
                }
                else{
                    dispatch(updateMySources([]));

                }
                console.log("MYSOURCES UPDATED: " + JSON.stringify(mysources));

                navigate('/', { replace: true })
            }
            else{
                setStatus(res.status);
                dispatch(AccessData({AccessToken:  '',  isAuthenticated: 'false', username: ''}));
            }



      }}>Login</Button>

       
      </div>
      <div>{Status}</div>



          
          
        </FormControl>    
 

    </Box>



    );
   }
    
   export default Login;